import React from 'react'
/** @jsx jsx */
import {css,jsx} from '@emotion/core'
import styled from '@emotion/styled'


// Components
import Subtitle1 from '../atoms/subtitle-1';
import Paragraph1 from '../atoms/paragraph-1';
import Button1 from '../atoms/button-1';
import Header2 from '../atoms/header-2';
import QuestionAnswer from '../molecules/question-answer';
import FormQuestion from '../atoms/form-question';
import Header1 from '../atoms/header-1';

// Styles
const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 60px 0;
  border-bottom: 1px solid #AAAAAA;
  &:first-of-type {
    border-top: 1px solid #AAAAAA;
  }
`
// const ContainerDate = styled.section`
//   order: 1;
//   width: 100%;
//   margin-bottom: 15px;
// `
const ContainerMain = styled.section`
  order: 2;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 250px;
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
    button {
      margin-top: 15px;
    }
  }
`
const ContainerMainContent = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: calc(70% - 15px);
  padding: 0 15px 0 0;
  @media (max-width: 480px) {
    order: 2;
    padding: 0 15px;
    width: auto;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
`
const ContainerMainImage = styled.section`
  width: 30%;
  max-width: 250px;
  @media (max-width: 480px) {
    order: 1
  }
`
const ContainerStudyGuide = styled.section`
  height: 0px;
  overflow-y: scroll;
  > div {
    p, h3, h4, li {
      font-family: 'Crimson Text';
    }
    p, li {
      font-size: 1.375em;
    }
    h3 {
      font-size: 2em;
    }
    h4 {
      font-size: 1.5em;
    }
  }
  @media (max-width: 480px) {
    order: 3
  }
`
const Overlay = styled.section`
  z-index: 100;
  opacity: 1;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,0.6);
  width: 100%;
  height: 100%;
`
const OverlayContainer = styled.section`
  position: relative;
  background: #EEEEEE;
  padding: 60px;
  width: 980px;
  @media(max-width: 768px) {
    height: calc(100vh - 60px);
    width: 100%;
    padding: 60px 15px;
    textarea {
      height: 150px;
      min-height: 100px;
    }
    p {
      text-align: center;
    }
  }
`
const OverlayContainerClose = styled.section`
  z-index: 2;
  position: relative;
  display: block;
  left: 0;
  right: 0;
  margin: 30px auto 0 auto;
  text-align: center;
  @media(max-width: 768px) {
    margin 15px auto 0 auto;
  }
`

class ArticleBlock extends React.Component { 
  constructor(props) {
    super(props)

    this.state = {
      contentVisible: false,
      formVisible: false
    }

    this.showContent = this.showContent.bind(this)
    this.showForm = this.showForm.bind(this)
    this.closeForm = this.closeForm.bind(this)
  }

  showContent() {
    if(typeof window !== 'undefined') {
      document.querySelector('#form-overlay').scrollTop = '0'
    }

    this.setState({contentVisible: !this.state.contentVisible ? true : false})
  }

  showForm(index) {
    if(typeof window !== 'undefined') {
      document.querySelector('body').style.overflow = 'hidden'
    }

    this.setState({
      formVisible: true,
    })
  }

  closeForm(e) {
    if(typeof window !== 'undefined') {
      document.querySelector('body').style.overflow = 'visible'
    }

    if(e.target.id === 'form-overlay' || e.target.id === 'btn-close') {
      this.setState({
        formVisible: false,
      })
    }
  }

  render() {
    const { 
      title, 
      subtitle, 
      description, 
      buttonColor,
      image,
      content,
      qas
    } = this.props

    return (
      <>
        <Container>
          <ContainerMain>
            <ContainerMainContent>
              <div>
                <Header2 spacing='0px'>{title}</Header2>
                <br/>
                <Subtitle1>{subtitle}</Subtitle1>
                <Paragraph1>{description}</Paragraph1>
              </div>

              <Button1 
                onClick={this.showContent} 
                color={this.state.contentVisible ? buttonColor : '#FFFFFF'}
                borderColor={buttonColor}
                backgroundColor={this.state.contentVisible ? '#FFFFFF' : buttonColor}
                hoverColor={buttonColor}
                hoverBorderColor={buttonColor}
              >
                {this.state.contentVisible ? 'Close Guide' : 'Open Guide'}
              </Button1>
            </ContainerMainContent>
            <ContainerMainImage>
              {image}
            </ContainerMainImage>

            <ContainerStudyGuide 
              css={css`
                transition: 0.5s;
                height: ${this.state.contentVisible ? '600px' : '0px'};
                margin-top: ${this.state.contentVisible ? '60px' : '0px'};
              `}
            >
              <div dangerouslySetInnerHTML={{ __html: content}}/>
              <Button1 spacing={'30px'} onClick={this.showForm}>Submit a Question...</Button1>
              <Overlay 
                css={css`
                  transition: 0.5s;
                  opacity: ${this.state.formVisible ? '1' : '0'};
                  pointer-events: ${this.state.formVisible ? 'initial' : 'none'};
                `}
                id='form-overlay' 
                onClick={this.closeForm}
              >
                <OverlayContainer
                  css={css`
                    transition: 0.5s;
                    transform: ${this.state.formVisible ? 'translateY(0)' : 'translateY(60px)'};
                  `}
                >
                  <Header1 spacing={'0'}>Submit a Question</Header1>
                  <FormQuestion subject={subtitle}/>
                  <OverlayContainerClose>
                    <Button1 id='btn-close' onClick={this.closeForm}>Close</Button1>
                  </OverlayContainerClose>
                </OverlayContainer>
              </Overlay>

              {qas && 
                qas.map((qa, j) => (
                  <QuestionAnswer
                    key={j}
                    question={qa.question}
                    answer={qa.answer}
                  />
                ))
              }
            </ContainerStudyGuide>

          </ContainerMain>
        </Container>
      </>
    )
  }
}

export default ArticleBlock