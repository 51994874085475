/** @jsx jsx */
import {jsx, css} from '@emotion/core'

const Header1 = ({
  children, 
  align='left', 
  color='#8BC4A2',
  fontFamily='Dancing Script', 
  fontSize='3em',
  fontWeight='800',
  spacing='45px'
}) => (
  <h1 
    css={css`
      color: ${color};
      font-family: ${fontFamily};
      font-size: ${fontSize};
      font-weight: ${fontWeight};
      margin: 0 0 ${spacing} 0;
      text-align: ${align};
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
    `}
  >
    {children}
  </h1>
)

export default Header1