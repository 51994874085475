import React from "react"
import styled from '@emotion/styled'

// Components
import Layout from "../../components/layout";
import ContentOverflow from "../../components/organisms/content-overflow";
import Footer from "../../components/organisms/footer";
import ArticleBlock from "../../components/organisms/article-block";
import Header1 from "../../components/atoms/header-1";

// Images
import ImgSOTBSGPlaceholder from "../../components/images/study-guides/the-story-of-the-bible/img-sotb-sg-placeholder";
import SEO from "../../components/organisms/seo";

// Styles
const TitleWrapper = styled.section`
  border-bottom: 2px solid #DADADA;
  margin-bottom: 30px;
`
const Spacer = styled.section`
  height: 250px;
`
const Spacer2 = styled.section`
  height: 250px;
  @media(max-width: 1100px) {
    height: 0px;
  }
`

const dataArticles = [
  {
    date: 'February 1st, 2019',
    title: 'The Story of the Bible',
    subtitle: 'Part 1: Why Do I Need A Message From God?',
    image: <ImgSOTBSGPlaceholder/>,
    content: `
      <h4>IDEA 1</h4>

      <p>You may have questions about your existence like these:</p>

      <ul>
      <li>Why is there evil in the world?</li>
      <li>Is the world supposed to be in the situation it’s in?</li>
      <li>Does it matter that I am here?</li>
      <li>Is there any way to make sense of it all?</li>
      <li>Why do I even have these questions?</li>
      </ul>

      <h4>IDEA 2</h4>
      <p>The Bible claims to be God’s answers to these questions.  Its message is that your situation is a whole lot worse than you thought it was, but that He has provided a way out for you.  And God has communicated His message by telling a story—the story of His work in human history, revealing His character and His plan for the world little by little.   That is the message of the Bible which is summarized in this book.</p>
    `,
    // qas: [
    //   {
    //     question: 'WHO IS GOD? And how could anyone expect to find that out?',
    //     answer: 'The only way is if God Himself were to tell you who He is. This book contends that God has done just that!'
    //   }
    // ],
  },
  {
    date: 'February 1st, 2019',
    title: 'The Story of the Bible',
    subtitle: "Part 2: Can I Trust The Authenticity Of The Bible?",
    image: <ImgSOTBSGPlaceholder/>,
    content: `
    <h4>IDEA 1</h4>
    <p>Many people believe that the Bible did not really come from God—that it has changed over the years or that it is just a collection of myths.  This chapter details some history about how the Bible came to be written.  It also gives evidence from fulfilled prophecy, from forensic investigation and from archaeological discovery that the Bible is exactly what it claims to be—and that it can be trusted to provide the answers you need.</p>

    <h4>IDEA 2</h4>
    <p>More than any outside evidence, it’s the internal evidence of the Bible that establishes its uniqueness.  There’s a cohesiveness and consistency of its themes from beginning to end, even though it was written by many human authors over centuries of time.  You will understand that God’s Spirit speaks through the Bible when you read it, because you will recognize that God’s Spirit is speaking to you.</p>
  `
  },
  {
    date: 'February 1st, 2019',
    title: 'The Story of the Bible',
    subtitle: "Part 3: What Can I Know About God?",
    linkColor: '#6396D1',
    image: <ImgSOTBSGPlaceholder/>,
    content: `
    <h4>IDEA 1</h4>
    <p>Did you know that the Bible makes no attempt to prove the existence of God?  Why do you suppose that this is?  Could it be that it’s because God is the starting point of everything?  It’s like in geometry you don’t prove a point or a line; you just start from there.  Without God there would be nothing.  That means no thing.  Neither you nor I nor anything else would exist.</p>

    <h4>IDEA 2</h4>
    <p>Just as it is impossible to prove the existence of God, it is also impossible to define Him.  He is too vast, too all-encompassing.  That’s why many of His names and descriptive adjectives in the Bible start with “all” or “ever”—All-knowing, All-powerful, Ever-present, Everlasting.   This chapter explains how we learn from the Bible that God is a Trinity—one God existing in three Persons.  We learn that He is Sovereign—He rules over everything.  And He is holy—He always does what is right.  But while He is totally infinite, at the same time He is personal.  He cares for each of us individually, He feels, He communicates.  All these things and much more we learn about God as we begin our journey through the Bible.</p>
  `
  },
  {
    date: 'February 1st, 2019',
    title: 'The Story of the Bible',
    subtitle: "Part 4: Who Is The Villain Of This Story?",
    linkColor: '#6396D1',
    image: <ImgSOTBSGPlaceholder/>,
    content: `
    <h4>IDEA 1</h4>
    <p>Every drama has to have a villain.  This villain is the granddaddy of all scoundrels.  From the highest heights he fell to the lowest depths.  Actually God created him to be the chief of all His good angels.  His name was Lucifer, “morning star.”  The problem was he became proud of his beauty and dissatisfied with his position; he wanted the supreme place “like the Most High.”  He stirred up mutiny among the hosts of Heaven; a third of the angels joined in his rebellion.   And God cast them all out of Heaven.  From that point on, Lucifer has been God’s sworn enemy.   Not able to harm God directly, Lucifer—now known as Satan—uses all his power to disrupt God’s plan and destroy God’s work.  The objects of God’s greatest love—that would be the people God created—are now the focus of Satan’s most vicious schemes.</p>
    
    <h4>IDEA 2</h4>
    <p>All kinds of questions arise from this story.  Why, if God is supreme, did he allow His creation to rebel against Him?  Why would one of God’s creation want to rebel against a good God?  Will this rebellion keep going on forever?  What will God do about it?  You will have to read this chapter (and the rest of the book) to find out.</p> 
  `
  },
]

const TheStoryOfTheBiblePage = () => (
  <Layout>
    
    <SEO title="Study Guide | The Story of the Bible | Beverly Hubbard" keywords={[`beverly hubbard`, `beverly`, `hubbard`, `christian author`, `christian`, `author`, `the story of the bible`, `book writer`, `christian book author`, `study`, `guide`, `study guide`]} />
    
    <Spacer2/>

    <ContentOverflow topOverflow='100px' bottomOverflow='0'>
      <>
        <TitleWrapper>
          <Header1 spacing='30px'>
            Study Guide - The Story of the Bible
          </Header1>
        </TitleWrapper>

        {dataArticles && 
          dataArticles.map((article, i) => (
            <ArticleBlock
              key={i}
              date={article.date}
              title={article.title}
              subtitle={article.subtitle}
              description={article.description}
              buttonColor={ i % 3 === 0 ? '#745077' : i % 3 === 1 ? '#8BC4A2' : '#6396D1'}
              image={article.image}
              content={article.content}
              qas={article.qas}
            />
          ))
        }
      </> 
    </ContentOverflow>

    <Spacer/>

    <Footer/>

  </Layout>
)

export default TheStoryOfTheBiblePage