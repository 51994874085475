// eslint-disable-next-line
import React from 'react'
/** @jsx jsx*/
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'

// Components
import MediaQuery from 'react-responsive'

// Styles
const ContainerOverflow = styled.section`
  z-index: 1;
  position: relative;
  height: calc(120% + 100px);
  background: #FFFFFF;
  max-width: 980px;
  padding: 60px;
  @media (max-width: 768px) {
    padding: 60px 30px;
  }
`

const ContentOverflow = ({ children, topOverflow='50px', bottomOverflow='50px', backgroundColor='transparent' }) => (
  <>
    <MediaQuery query="(min-width: 1101px)">
      <section css={css`
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: -${topOverflow} 0 -${bottomOverflow} 0;
        background: linear-gradient(180deg, rgba(0,0,0,0) ${topOverflow}, ${backgroundColor} 0%), linear-gradient(0deg, rgba(0,0,0,0) ${bottomOverflow}, transparent 0%);
      `}>
        <ContainerOverflow>
          {children}
        </ContainerOverflow>
      </section>
    </MediaQuery>
    <MediaQuery query="(max-width: 1100px)">
      <section css={css`
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        background: ${backgroundColor};
      `}>
        <ContainerOverflow>
          {children}
        </ContainerOverflow>
      </section>
    </MediaQuery>
  </>
)

export default ContentOverflow