/** @jsx jsx*/
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import Paragraph1 from '../atoms/paragraph-1';


// Styles
const Container = styled.section`
  width: 100%;
  margin-bottom: 15px;
`
const ContainerQuestion = styled.section`
  padding: 15px 30px;
  border: 1px solid #ECECEC;
  border-bottom: none;
`
const ContainerAnswer = styled.section`
  padding: 15px 30px;
  border: 1px solid #ECECEC;
`


const QuestionAnswer = ({question, answer}) => (
  <Container>
    <ContainerQuestion>
      <Paragraph1><b css={css`color: #000000;`}>Question:</b> {question}</Paragraph1>
    </ContainerQuestion>
    <ContainerAnswer>
      <Paragraph1 color={'#6396D1'}><b css={css`color: #000000;`}>Answer:</b> {answer}</Paragraph1>
    </ContainerAnswer>
  </Container>
)

export default QuestionAnswer