/** @jsx jsx */
import { css, jsx } from '@emotion/core'

const Button1 = ({
    children,
    id,
    name,
    type,
    align='center',
    spacing='0px',
    color='#FFFFFF',
    backgroundColor='#745077',
    borderColor='#745077',
    hoverColor='#745077',
    hoverBackgroundColor='#FFFFFF',
    hoverBorderColor='#745077',
    fontFamily='Palanquin',
    fontSize='1.25em',
    onClick
  }) => (
  <button
    id={id}
    type={type}
    name={name}
    onClick={onClick}
    css={css`
      outline: none;
      display: inline-block;
      min-width: 150px;
      min-height: 45px;
      padding: 5px 10px;
      font-weight: 300;
      text-decoration: none;
      transition: 0.2s;
      cursor: pointer;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      
      border: 2px solid ${borderColor};
      text-align: ${align};
      color: ${color};
      background: ${backgroundColor};
      font-family: ${fontFamily};
      font-size: ${fontSize};
      margin-bottom: ${spacing};
      &:hover {
        color: ${hoverColor};
        background: ${hoverBackgroundColor};
        border: 2px solid ${hoverBorderColor};
      }
    `}
  >
    {children}
  </button>
)

export default Button1