/** @jsx jsx */
import {jsx, css} from '@emotion/core'

const Subtitle1 = ({
  children, 
  align='left', 
  color='#4C4C4C', 
  fontFamily='Crimson Text', 
  fontSize='1.5em', 
  fontWeight='100',
  spacing='0px'
}) => (
  <h5
    css={css`
      display: inline-block;
      color: ${color};
      font-family: ${fontFamily};
      font-size: ${fontSize};
      font-weight: ${fontWeight};
      margin: 0 0 ${spacing} 0;
      text-align: ${align};
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
    `}
  >
    {children}
  </h5>
)

export default Subtitle1